<template>

  <h1 class="Transmision_title">Información Transmisión</h1>

  <p class="text-header">
    Conoce el resumen de tu transmisión de nómina, en caso de que requieras
    descargar la información haz clic en el botón <span><b>Descargar</b></span>
    para tener a tu disposición un archivo Excel con todo el detalle de la
    transmisión.
  </p>

<el-dropdown class="Button_BColorDegradedDownload btn-position">
    <span>           
      <font-awesome-icon
        :icon="['fas', 'download']"
        style="padding-left: 5px"
        size="lg"
        /> Descargar
    </span>
     <template #dropdown>
        <el-dropdown-menu class="menuOpcionesDescarga">         
        <el-dropdown-item
           @click="DescargarArchivoNominaExcel()">
          <font-awesome-icon
            :icon="['fas', 'file-excel']"
            style="color: #1e6e24"
            size="lg"/> Descargar en Excel
        </el-dropdown-item>
          <el-dropdown-item
            @click="DescargarArchivoNominaXml(item, 'ZIP')">
            <div class="xml-container">
              <img
                :src="require('@/assets/img/green-xml.svg')"
                height="23"
                width="18"
                id="img-xml"
                />
                <p class="texto">Descargar en XML</p>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  
  <a class="btn-back btn-position" @click="GoToSummary()">
    <font-awesome-icon :icon="['fas', 'arrow-left']" size="xs" /> Volver
  </a>

  <Dialog
    v-model:visible="dialogVisible"
    modal
    :closable="false"
    header="Importante"
    :draggable="false"
    id="p-dialog"
    class="error-dialog"
  >
    <img src="../assets/img/Warning.png" width="60" height="60" />

    <p>
      No es posible descargar la información, inténtalo de nuevo, si el error persiste contáctanos.
    </p>

    <template #footer>
      <Button icon="pi pi-times" @click="CerrarModal" text>← Volver</Button>
    </template>
  </Dialog>

  <TabView ref="tabView" :activeIndex="activeTab">
    <TabPanel header="Trabajador">
      <InformacionTrabajador
        v-if="empleado && detalleNomina"
        :empleado="empleado"
        :detalle="detalleNomina"
        :fechaIngreso="fechaIngreso"
        :fechaAprobacion="fechaAprobacion">
      </InformacionTrabajador>
    </TabPanel>

    <TabPanel header="Liquidación de nómina">
      <LiquidacionNomina
        v-if="nomina && detalleNomina && empleado"
        :empleado="empleado"
        :liquidacion="nomina"
        :detalle="detalleNomina"
        :fechaIngreso="fechaIngreso"
        :fechaAprobacion="fechaAprobacion">
      </LiquidacionNomina>
    </TabPanel>

    <TabPanel header="Otros devengos y deducciones">
      <DeduccionesNomina
        v-if="nomina && detalleNomina && empleado"
        :empleado="empleado"
        :deducciones="nomina"
        :detalle="detalleNomina"
        :fechaIngreso="fechaIngreso"
        :fechaAprobacion="fechaAprobacion">
      </DeduccionesNomina>
    </TabPanel>
  </TabView>
</template>

<script>

import { ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { onMounted } from "vue-demi";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { useMutations } from "@/store/helper";
import "@/assets/sass/views/_PayrollDetail.scss";
import { DescargarArchivo } from "@/helpers/helpers";
import { TabDetail } from "@/models/Payroll/Enums";
import DeduccionesNomina from "@/components/DetalleNomina/DeduccionesNomina.vue";
import LiquidacionNomina from "@/components/DetalleNomina/LiquidacionNomina.vue";
import InformacionTrabajador from "@/components/DetalleNomina/InformacionTrabajador.vue";
import Dialog from "primevue/dialog";

export default {
  components: {
    TabView,
    TabPanel,
    InformacionTrabajador,
    LiquidacionNomina,
    DeduccionesNomina,
    Dialog,
  },
  setup() {

    const tabView = ref();
    const tabs = TabDetail;
    const store = useStore();
    const activeTab = ref(tabs.Trabajador);
    const { setStateLoading } = useMutations(["setStateLoading"]);
    const detalleNomina = ref();
    const nomina = ref({});
    const empleado = ref({});
    const fechaIngreso = ref("");
    const fechaAprobacion = ref("");
    const dialogVisible = ref(false);

    const CerrarModal = () => {
      dialogVisible.value = false;
    };

    function GoToSummary() {
      router.push({
        name: "SummaryTransmission",
      });
    }
    
    function ObtenerNominaEmpleado() {
      setStateLoading(true);

      let entradaConsulta = {
        token: localStorage.getItem("Tokensin"),
        detalleNominaId: store.state.DetalleNomina.DetalleNominaId,
      };

      store
        .dispatch("ObtenerNominaEmpleado", entradaConsulta)
        .then((response) => {
          empleado.value = response.data.empleado;
          detalleNomina.value = response.data.detalleNomina;

          const nominaEmpleado = JSON.parse(
            detalleNomina.value.jsonNomina
          );

          nomina.value = nominaEmpleado;
          fechaIngreso.value = nomina.value.AdmissionDate;
          fechaAprobacion.value = detalleNomina.value.fechaConsultaDIAN;

          setStateLoading(false);
        })
        .catch((error) => {
          if (error.response.status == 404) {
            GoToSummary();
          }
          setStateLoading(false);
          console.log(error.request.response);
        });
    }

    function DescargarArchivoNominaExcel() {
      setStateLoading(true);

      let entradaConsulta = {
        token: localStorage.getItem("Tokensin"),
        detalleNominaId: store.state.DetalleNomina.DetalleNominaId,
      };

      store
        .dispatch("DescargarArchivoNominaEmpleado", entradaConsulta)
        .then((response) => {
          DescargarArchivo(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            response.data.archivo,
            response.data.nombre
          );
          setStateLoading(false);
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error.request.response);
        });
    }
    function DescargarArchivoNominaXml() {
      setStateLoading(true);

      let entradaConsulta = {
        token: localStorage.getItem("Tokensin"),
        detalleNominaId: store.state.DetalleNomina.DetalleNominaId,
      };

      store
        .dispatch("DescargarArchivoNominaIndividualAprobada", entradaConsulta)
        .then((response) => {
          if(response.data.archivo == null || response.data == null)
          {
            dialogVisible.value = true;
          }
          else
          {
            DescargarArchivo(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            response.data.archivo,
            response.data.nombre
            );
          }         
          setStateLoading(false);
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error.request.response);
        });
    }
    onMounted(() => {
      ObtenerNominaEmpleado();
    });

    return {
      nomina,
      empleado,
      detalleNomina,
      GoToSummary,
      activeTab,
      tabView,
      DescargarArchivo,
      DescargarArchivoNominaExcel,
      DescargarArchivoNominaXml,
      fechaAprobacion,
      fechaIngreso,
      dialogVisible,
      CerrarModal,
    };
  },
};
</script>

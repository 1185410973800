import { createStore } from 'vuex'
import axios from 'axios'
import paymentModule from './Payment';
import ddModule from './DueDiligence';
import { TipoNomina } from '@/models/Payroll/Enums';
import { TipoMarca } from '@/models/Payroll/Enums';

const apiEmpleador = process.env.VUE_APP_Gateway_API + "Empleador/";
const apiNomina = process.env.VUE_APP_Gateway_API + "Nomina/";
const apiFront = process.env.VUE_APP_Gateway_API + "FrontEnd/";
const apiParametricas = process.env.VUE_APP_Gateway_API + "Parametricas/";
const apiConfiguracion = process.env.VUE_APP_Gateway_API + "ConfiguracionReglas/";

export default createStore({
  state: {
    StateMenu: false,
    TestSet: {
      Identificacion: "",
      Tipoidentificacion: "",
      TestSetDian: ""
    },
    TratamientoDatos: {
      Identificacion: "",
      Tipoidentificacion: "",
      EstadoAporbacion: false
    },
    ValidatorAccess: {
      Identificacion: "",
      Tipoidentificacion: ""
    },
    CargueArchivo: {
      NumeroIdentificacion: "",
      TipoIdentificacion: "",
      PeriodoTransmision: "",
      Archivo: ""
    },
    TyC: {
      Identificacion: "",
      Tipoidentificacion: "",
      EstadoAporbacion: false
    },
    DetalleEntradaNomina: {
      Anio: 0,
      Mes: 0,
      CantidadDocumentos: 0,
      CantidadAprobados: 0,
      CantidadErrores: 0,
      ErroresValidacion: 0,
      Periodo: '',
      Estado: ''
    },
    EntradaNomina: {
      EntradaNominaId: '',
      CantidadDocumentos: 0,
      CantidadAprobados: 0,
      CantidadErrores: 0,
      Periodo: ''
    },
    DetalleNomina: {
      DetalleNominaId: 0,
      TransmisionAjuste: false,
      TipoNomina: TipoNomina.NoDefinido,
      Empleado: {},
      JsonNomina: {},
      Detalle: {}
    },
    ParametricasTransmision: {
      Divipolas: Object,
      Parametricas: Object,
      HorasExtras: Object
    },
    DataEmpleador: {
      Anio: '',
      Mes: '',
      CantidadAprobados: 0,
    },
    meses: [
      {
        label: "ENERO",
        value: "1",
      },
      {
        label: "FEBRERO",
        value: "2",
      },
      {
        label: "MARZO",
        value: "3",
      },
      {
        label: "ABRIL",
        value: "4",
      },
      {
        label: "MAYO",
        value: "5",
      },
      {
        label: "JUNIO",
        value: "6",
      },
      {
        label: "JULIO",
        value: "7",
      },
      {
        label: "AGOSTO",
        value: "8",
      },
      {
        label: "SEPTIEMBRE",
        value: "9",
      },
      {
        label: "OCTUBRE",
        value: "10",
      },
      {
        label: "NOVIEMBRE",
        value: "11",
      },
      {
        label: "DICIEMBRE",
        value: "12",
      },
    ],
    StateLoading: false,
    ConfirmDialog: false,
    ErrorDialog: false,
    Transmitir: false,
    refresh: false,
    expresionRegularTipoIdentificacionPasarela:'',
    datosPagoTemp:{}
  },
  mutations: {
    setStateMenu(state, Data) {
      state.StateMenu = Data;
    },
    setCargueArchivo(state, Data) {
      state.CargueArchivo = Data;
    },
    setTestSet(state, Data) {
      state.TestSet = Data;
    },
    updateContent(state, value: boolean) {
      state.refresh = value;
    },
    setValidatorAccess(state, Data) {
      state.ValidatorAccess = Data;
    },
    setTratamientoDatos(state, Data) {
      state.TratamientoDatos = Data;
    },
    setTyC(state, Data) {
      state.TyC = Data;
    },
    setDetalleEntradaNomina(state, Data) {
      state.DetalleEntradaNomina = Data;
    },
    setDetalleNomina(state, Data) {
      state.DetalleNomina = Data;
    },
    setStateLoading(state, Data) {
      state.StateLoading = Data;
    },
    showConfirmDialog(state, Data) {
      state.ConfirmDialog = Data;
    },
    showErrorDialog(state, Data) {
      state.ErrorDialog = Data;
    },
    showTranssmission(state, Data) {
      state.Transmitir = Data;
    },
    setEntradaNominaId(state, Data) {
      state.EntradaNomina = Data;
    },
    setDataEmpleador(state, Data) {
      state.DataEmpleador = Data;
    },
    setParametricasTransmision(state, Data) {
      state.ParametricasTransmision = Data;
    },
    setExpresionRegularTipoIdentificacionPasarela(state, expresionRegular){
      state.expresionRegularTipoIdentificacionPasarela = expresionRegular;
    },
    setDatosPagoTemp(state,datosPago){
      state.datosPagoTemp = datosPago;
    }
  },
  actions: {
    updateExpresionRegularTipoIdentificacionPasarela({commit},expresionRegular){
      commit('setExpresionRegularTipoIdentificacionPasarela',expresionRegular);
    },
    cargarDatosPagoTemp({commit},datosPago){
      commit('setDatosPagoTemp',datosPago);
    },
    GetAccessProfiles({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.Token,
          AplicacionOrigen: data.apliOrigen,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + "GetEmpleadorAccesoPerfiles", config)
          .then((response) => {
            if (response.data != null) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      }

      )
    },
    GetVeifyAccess({ commit, dispatch }, RouteName) {
      let config = {
        headers: {
          Token: localStorage.getItem("Tokensin")
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiFront + "ValidarAccesoPagina?paginaAcceso=" + RouteName, config)
          .then((response) => {
            if (response.data != null) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      }

      )
    },
    SendTestSet({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.post(apiEmpleador + "SendTestSet", this.state.TestSet, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }

      )
    },
    SendStateQualificationEmpleador({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.post(apiEmpleador + "SendStateEmpleadorAproved", this.state.TratamientoDatos, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },
    SendStateEmpleadorTyC({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.post(apiEmpleador + "SendStateEmpleadorTyC", this.state.TyC, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },
    GetStateTestSet({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + "GetStateTestSet", config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },
    GetStateEmpleador({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + "GetStateEmpleador", config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },

    GetUrlVideo({ commit, dispatch }, Data) {
      let config = {
        headers: {
          Token: Data.Token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + "GetUrlVideo?NombreVideo="+Data.NombreVideo, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },

    CargarArchivoTransmision({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      let data = new FormData();
      data.append('NumeroIdentificacion', this.state.CargueArchivo.NumeroIdentificacion);
      data.append('TipoIdentificacion', this.state.CargueArchivo.TipoIdentificacion);
      data.append('PeriodoTransmision', this.state.CargueArchivo.PeriodoTransmision);
      data.append('Archivo', this.state.CargueArchivo.Archivo);
      return new Promise((resolve, reject) => {
        axios.post(apiNomina + "CargarArchivoTransmision", data, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request);
          });
      }

      )
    },
    GetListTransmission({ commit, dispatch }, Data) {
      let config = {
        headers: {
          Token: Data.Token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiNomina + "GetListTransmission?Anio=" + Data.Anio + "&Mes=" + Data.Mes, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },
    GetCertificate({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}GenerarCertificado?anio=${this.state.DataEmpleador.Anio}&mes=${this.state.DataEmpleador.Mes}&cantidadDocumentos=${this.state.DataEmpleador.CantidadAprobados}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },
    ObtenerErroresTransmision({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ObtenerErroresTransmision?anio=${this.state.DetalleEntradaNomina.Anio}&mes=${this.state.DetalleEntradaNomina.Mes}&entradaNominaId=${this.state.EntradaNomina.EntradaNominaId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerResumenTransmision({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ObtenerResumenTransmision?anio=${this.state.DetalleEntradaNomina.Anio}&mes=${this.state.DetalleEntradaNomina.Mes}&entradaNominaId=${this.state.EntradaNomina.EntradaNominaId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerHistorialTransmisiones({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ObtenerHistorialTransmisiones?anio=${this.state.DetalleEntradaNomina.Anio}&mes=${this.state.DetalleEntradaNomina.Mes}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerNominaEmpleado({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ObtenerNominaEmpleado?detalleNominaId=${entradaConsulta.detalleNominaId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    DescargarArchivoNominaEmpleado({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}DescargarArchivoNominaEmpleado?detalleNominaId=${entradaConsulta.detalleNominaId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    DescargarArchivoNominaIndividualAprobada({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}DescargarArchivoNominaIndividualAprobada?detalleNominaId=${entradaConsulta.detalleNominaId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerDetalleNominaEmpleado({ commit, dispatch }, entradaConsultaNomina) {
      let config = {
        headers: {
          Token: entradaConsultaNomina.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ObtenerDetalleNominaEmpleado?detalleNominaId=${entradaConsultaNomina.detalleNominaId}
          &empleadoId=${entradaConsultaNomina.empleadoId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerParametricasTransmision({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ObtenerParametricasTransmision`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ValidarTransmision({ commit }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}ValidarTransmision`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    TransmitirNomina({ commit }, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Token: data.token
          }
        }

        axios.post(`${apiNomina}TransmitirNomina`, data.entradaTransmision, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request);
          });
      })
    },
    GetImages({ commit, dispatch }, nombre) {
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + "DownloadImages?nombre=" + nombre)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    GetParametricasNE({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + "GetParametricasNE", config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      }

      )
    },
    UpdateEmployeerStatus({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.token
        }
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiEmpleador}UpdateEmployeerStatus`, data, config)
          .then((response: any) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err)
          });
      })
    },
    GetEmployerData({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + 'GetEmployerData', config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    SaveContactData({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.post(apiEmpleador + "SaveContactData", data.contactData, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      })
    },
    ValidateContactData({ commit, dispatch }, token) {
      let config = {
        headers: {
          Token: token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiEmpleador + 'ValidateContactData', config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    GetKeyVaultSecrets({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiFront}GetKeyVaultSecrets?secrets=${data.secret1}&secrets=${data.secret2}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    GetStatusFeatureFlag({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiFront + 'GetFeatureFlag?featureFlagName=' + data.featureFlagName, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerInfoMarca({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.Token,
          TipoMarca: TipoMarca.CompraPlanes
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiNomina + 'ObtenerInfoMarca', config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    DescargarArchivoNominasAprobadas({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.Token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiNomina}DescargarArchivoNominasAprobadas?anio=${this.state.DataEmpleador.Anio}&mes=${this.state.DataEmpleador.Mes}&tipoArchivo=${data.TipoArchivo}`, config)
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error.request.response);
        });
      })
    },
    ObtenerNominasEnProcesamiento({ commit, dispatch }, Data) {
      let config = {
        headers: {
          Token: Data.Token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(apiNomina + "ObtenerNominasEnProcesamiento", config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      })
    },
    ObtenerDepartamentosYMunicipiosDian({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiParametricas}ObtenerDepartamentosYMunicipiosDian`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    ObtenerListasParaDireccion({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiParametricas}ObtenerListasParaDireccion`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    EliminarNominaColaTransmision({ commit, dispatch }, data) {
      let config = {
        headers: {
          Token: data.token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.delete(`${apiNomina}EliminarNominaColaTransmision?entradaNominaId=${data.entradaNominaId}`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request.response);
          });
      })
    },
    ObtenerReglasPorCategoria({ commit, dispatch }, entradaConsulta) {
      let config = {
        headers: {
          Token: entradaConsulta.Token,
        }
      }
      return new Promise((resolve, reject) => {
        axios.get(`${apiConfiguracion}ObtenerReglasPorCategoria`, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    RegistrarHistorialReglas({ commit }, entradaConsulta) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Token: entradaConsulta.Token
          }
        }
        axios.post(`${apiConfiguracion}RegistrarHistorialReglas`, entradaConsulta.HistorialReglas, config)
          .then((response) => {
            resolve(response);
          })
          .catch(error => {
            reject(error.request);
          });
      })
    },
  },
  modules: {
    paymentModule,
    ddModule
  }
})
